import { Property } from "./interfaces";

export const causaMotivoAtencion: Property = {
  title: "Causa Motivo Atención",
  type: "string",
  oneOf: [
    { const: "21", title: "21: Accidente de trabajo" },
    { const: "22", title: "22: Accidente en el hogar" },
    { const: "23", title: "23: Accidente de tránsito de origen común" },
    { const: "24", title: "24: Accidente de tránsito de origen laboral" },
    { const: "25", title: "25: Accidente en el entorno educativo" },
    { const: "26", title: "26: Otro tipo de accidente" },
    { const: "27", title: "27: Evento catastrófico de origen natural" },
    { const: "28", title: "28: Lesión por agresión" },
    { const: "29", title: "29: Lesión auto infligida" },
    { const: "30", title: "30: Sospecha de violencia física" },
    { const: "31", title: "31: Sospecha de violencia psicológica" },
    { const: "32", title: "32: Sospecha de violencia sexual" },
    { const: "33", title: "33: Sospecha de negligencia y abandono" },
    {
      const: "34",
      title: "34: IVE relacionado con peligro a la Salud o vida de la mujer",
    },
    {
      const: "35",
      title: "35: IVE por malformación congénita incompatible con la vida",
    },
    {
      const: "36",
      title:
        "36: IVE por violencia sexual, incesto o por inseminación artificial o transferencia de óvulo fecundado no consentida",
    },
    { const: "37", title: "37: Evento adverso en salud" },
    { const: "38", title: "38: Enfermedad general" },
    { const: "39", title: "39: Enfermedad laboral" },
    {
      const: "40",
      title:
        "40: Promoción y mantenimiento de la salud – intervenciones individuales",
    },
    { const: "41", title: "41: Intervención colectiva" },
    { const: "42", title: "42: Atención de población materno perinatal" },
    { const: "43", title: "43: Riesgo ambiental" },
    { const: "44", title: "44: Otros eventos Catastróficos" },
    { const: "45", title: "45: Accidente de mina antipersonal – MAP" },
    {
      const: "46",
      title: "46: Accidente de Artefacto Explosivo Improvisado – AEI",
    },
    { const: "47", title: "47: Accidente de Munición Sin Explotar- MUSE" },
    { const: "48", title: "48: Otra víctima de conflicto armado colombiano" },
    {
      const: "49",
      title:
        "49: IVE: Por decisión o manifestación de voluntad de la persona gestante hasta la semana 24 de gestación",
    },
  ],
};
