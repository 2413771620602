import { Property } from "./interfaces";

export const tipoUsuario: Property = {
    title: "Tipo de Usuario",
    type: "string",
    oneOf: [
        { const: "01", title: "01: Contributivo cotizante" },
        { const: "02", title: "02: Contributivo beneficiario" },
        { const: "03", title: "03: Contributivo adicional" },
        { const: "04", title: "04: Subsidiado" },
        { const: "05", title: "05: No afiliado" },
        { const: "06", title: "06: Especial o Excepción cotizante" },
        { const: "07", title: "07: Especial o Excepción beneficiario" },
        { const: "08", title: "08: Personas privadas de la libertad a cargo del Fondo Nacional de Salud" },
        { const: "09", title: "09: Tomador / Amparado ARL" },
        { const: "10", title: "10: Tomador / Amparado SOAT" },
        { const: "11", title: "11: Tomador / Amparado Planes Voluntarios de Salud"},
        { const: "12", title: "12: Particular"},
        { const: "13", title: "13: Especial o excepción no cotizante Ley 352 de 1997"}
    ]
}