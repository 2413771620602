import { codComplicacion } from "../../commonSchemas/codComplicacion";
import { codDiagnosticoPrincipal } from "../../commonSchemas/codDiagnosticoPrincipal";
import { codDiagnosticoRelacionado } from "../../commonSchemas/codDiagnosticoRelacionado";
import { codPrestador } from "../../commonSchemas/codPrestador";
import { codProcedimiento } from "../../commonSchemas/codProcedimiento";
import { codServicio } from "../../commonSchemas/codServicio";
import { consecutivo } from "../../commonSchemas/consecutivo";
import { fechaInicioAtencion } from "../../commonSchemas/fechaInicioAtencion";
import { finalidadTecnologiaSalud } from "../../commonSchemas/finalidadTecnologiaSalud";
import { grupoServicios } from "../../commonSchemas/grupoServicios";
import { idMIPRES } from "../../commonSchemas/idMIPRES";
import { Property } from "../../commonSchemas/interfaces";
import { modalidadGrupoServicioTecSal } from "../../commonSchemas/modalidadGrupoServicioTecSal";
import { numeroAutorizacion } from "../../commonSchemas/numeroAutorizacion";
import { numDocumentoIdentificacion } from "../../commonSchemas/numDocumentoIdentificacion";
import { numFEVPagoModerador } from "../../commonSchemas/numFEVPagoModerador";
import { tipoDocumentoIdentificacion } from "../../commonSchemas/tipoDocumentoIdentificacion";
import { conceptoRecaudo } from "../../commonSchemas/conceptoRecaudo";
import { valorPagoModerador } from "../../commonSchemas/valorPagoModerador";
import { viaIngresoServicioSalud } from "../../commonSchemas/viaIngresoServicioSalud";
import { vrServicio } from "../../commonSchemas/vrServicio";
import { tipoDocumentoIdentificacionProfesional } from "../../commonSchemas/tipoDocumentoIdentificacionProfesional";

export const procedimientos: Property = {
  title: "Agregar procedimiento",
  type: "array",
  items: {
    type: "object",
    properties: {
      codPrestador,
      fechaInicioAtencion,
      idMIPRES,
      numAutorizacion: { ...numeroAutorizacion },
      codProcedimiento,
      viaIngresoServicioSalud,
      modalidadGrupoServicioTecSal,
      grupoServicios,
      codServicio,
      finalidadTecnologiaSalud,
      tipoDocumentoIdentificacion: {
        ...tipoDocumentoIdentificacionProfesional,
      },
      numDocumentoIdentificacion,
      codDiagnosticoPrincipal,
      codDiagnosticoRelacionado,
      codComplicacion,
      vrServicio,
      conceptoRecaudo: {
        ...conceptoRecaudo,
        oneOf: [
          { const: "01", title: "01: Copago" },
          { const: "02", title: "02: Cuota moderadora" },
          {
            const: "03",
            title: "03: Pagos compartidos en planes voluntarios de salud",
          },
          { const: "05", title: "05: No aplica" },
        ],
      },
      valorPagoModerador,
      numFEVPagoModerador,
      consecutivo,
    },
  },
};
