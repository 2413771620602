import { Property } from "./interfaces";

export const conceptoRecaudo: Property = {
  title: "Concepto recaudo",
  type: "string",
  oneOf: [
    { const: "01", title: "01: Copago" },
    { const: "02", title: "02: Cuota moderadora" },
    {
      const: "03",
      title: "03: Pagos compartidos en planes voluntarios de salud",
    },
    { const: "04", title: "04: Anticipo" },
    { const: "05", title: "05: No aplica" },
  ],
};
