import { cantidadMedicamento } from "../../commonSchemas/cantidadMedicamento";
import { codDiagnosticoPrincipal } from "../../commonSchemas/codDiagnosticoPrincipal";
import { codDiagnosticoRelacionado } from "../../commonSchemas/codDiagnosticoRelacionado";
import { codPrestador } from "../../commonSchemas/codPrestador";
import { codTecnologiaSalud } from "../../commonSchemas/codTeconologiaSalud";
import { concentracionMedicamento } from "../../commonSchemas/concentracionMedicamento";
import { consecutivo } from "../../commonSchemas/consecutivo";
import { diasTratamiento } from "../../commonSchemas/diasTratamiento";
import { fechaDispensAdmon } from "../../commonSchemas/fechaDispensAdmon";
import { formaFarmaceutica } from "../../commonSchemas/formaFarmaceutica";
import { idMIPRES } from "../../commonSchemas/idMIPRES";
import { Property } from "../../commonSchemas/interfaces";
import { nomTecnologiaSalud } from "../../commonSchemas/nomTecnologiaSalud";
import { numeroAutorizacion } from "../../commonSchemas/numeroAutorizacion";
import { numDocumentoIdentificacion } from "../../commonSchemas/numDocumentoIdentificacion";
import { numFEVPagoModerador } from "../../commonSchemas/numFEVPagoModerador";
import { tipoDocumentoIdentificacion } from "../../commonSchemas/tipoDocumentoIdentificacion";
import { tipoMedicamento } from "../../commonSchemas/tipoMedicamento";
import { conceptoRecaudo } from "../../commonSchemas/conceptoRecaudo";
import { unidadMedida } from "../../commonSchemas/unidadMedida";
import { unidadMinDispensa } from "../../commonSchemas/unidadMinDispensa";
import { valorPagoModerador } from "../../commonSchemas/valorPagoModerador";
import { vrServicio } from "../../commonSchemas/vrServicio";
import { vrUnitMedicamento } from "../../commonSchemas/vrUnitMedicamento";
import { tipoDocumentoIdentificacionProfesional } from "../../commonSchemas/tipoDocumentoIdentificacionProfesional";

export const medicamentos: Property = {
  title: "Agregar registro de medicamento",
  type: "array",
  items: {
    type: "object",
    properties: {
      codPrestador,
      numAutorizacion: { ...numeroAutorizacion },
      idMIPRES,
      fechaDispensAdmon,
      codDiagnosticoPrincipal,
      codDiagnosticoRelacionado,
      tipoMedicamento,
      codTecnologiaSalud,
      nomTecnologiaSalud,
      concentracionMedicamento,
      unidadMedida,
      formaFarmaceutica,
      unidadMinDispensa,
      cantidadMedicamento,
      diasTratamiento,
      tipoDocumentoIdentificacion: {
        ...tipoDocumentoIdentificacionProfesional,
      },
      numDocumentoIdentificacion,
      vrUnitMedicamento,
      vrServicio,
      conceptoRecaudo: {
        ...conceptoRecaudo,
        oneOf: [
          { const: "02", title: "02: Cuota moderadora" },
          {
            const: "03",
            title: "03: Pagos compartidos en planes voluntarios de salud",
          },
          { const: "05", title: "05: No aplica" },
        ],
      },
      valorPagoModerador,
      numFEVPagoModerador,
      consecutivo,
    },
  },
};
