import { Property } from "./interfaces";

export const viaIngresoServicioSalud: Property = {
  title: "Vía de Ingreso al Servicio de Salud",
  type: "string",
  oneOf: [
    {
      const: "01",
      title: "01: Demanda espontánea",
    },
    {
      const: "02",
      title: "02: Derivado de consulta externa",
    },
    {
      const: "03",
      title: "03: Derivado de urgencias",
    },
    {
      const: "04",
      title: "04: Derivado de hospitalización",
    },
    {
      const: "05",
      title: "05: Derivado de sala de cirugía",
    },
    {
      const: "06",
      title: "06: Derivado de sala de partos",
    },
    {
      const: "07",
      title: "07: Recién nacido en la institución",
    },
    {
      const: "08",
      title: "08: Recién nacido en otra institución",
    },
    {
      const: "09",
      title: "09: Derivado o referido de hopitalización domiciliaria",
    },
    {
      const: "10",
      title: "10: Derivado de atención domiciliaria",
    },
    {
      const: "11",
      title: "11: Derivado de telemedicina",
    },
    {
      const: "12",
      title: "12: Derivado de jornada de salud",
    },
    {
      const: "13",
      title: "13: Referido de otra institución",
    },
    {
      const: "14",
      title: "14: Contrarreferido de otra institución",
    },
  ],
};
