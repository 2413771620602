import { Property } from "./interfaces";

export const codSexoBiologico: Property = {
  title: "Código de Sexo Biológico",
  type: "string",
  oneOf: [
    { const: "F", title: "F: Femenino" },
    { const: "M", title: "M: Masculino" },
    { const: "I", title: "I: Indeterminado o Intersexual" },
  ],
};
