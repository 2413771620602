import { Property } from "./interfaces";

export const codSexo: Property = {
  title: "Código de Sexo",
  type: "string",
  oneOf: [   
    { const: "F", title: "F: Femenino" },
    { const: "M", title: "M: Masculino" },
    { const: "I", title: "I: Indeterminado o Intersexual" },
  ],
};
