import { Property } from "./interfaces";

export const tipoDocumentoIdentificacionProfesional: Property = {
  title: "Tipo de Documento de Identificación",
  type: "string",
  oneOf: [
    { const: "CC", title: "CC: Cédula de ciudadanía" },
    { const: "CD", title: "CD: Carné diplomático" },
    { const: "CE", title: "CE: Cédula de extranjería" },
    { const: "DE", title: "DE: Documento Extranjero" },
    { const: "PA", title: "PA: Pasaporte" },
    { const: "PE", title: "PE: Permiso especial de permanencia" },
    { const: "PT", title: "PT: Permiso por protección temporal" },
    { const: "SC", title: "SC: Salvoconducto de permanencia" },
  ],
};
